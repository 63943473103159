import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})

export class DialogComponent implements OnInit {
    fragranceImage: any;
    fragranceArticleNo: any;
    fragranceSpecification1: any;
    fragranceSpecification2: any;
    fragranceSpecification3: any;
    fragranceSpecification4: any;
    fragranceSpecification5: any;
    fragranceDescription: any;
    selectedIndex: any;
    images: [];
    articlenos: [];
    specifications1: [];
    specifications2: [];
    specifications3: [];
    specifications4: [];
    specifications5: [];
    description: [];
    constructor(public dialogbox: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.images = data.productImage;
        this.articlenos = data.productArticleNo;
        this.specifications1 = data.specification1;
        this.specifications2 = data.specification2;
        this.specifications3 = data.specification3;
        this.specifications4 = data.specification4;
        this.specifications5 = data.specification5;
        this.description = data.fullDescription;

        this.fragranceImage = data.productImage[0];
        this.fragranceArticleNo = data.productArticleNo[0];
        this.fragranceSpecification1 = data.specification1[0];
        this.fragranceSpecification2 = data.specification2[0];
        this.fragranceSpecification3 = data.specification3[0];
        this.fragranceSpecification4 = data.specification4[0];
        this.fragranceSpecification5 = data.specification5[0];
        this.fragranceDescription = data.fullDescription[0];
    }

    ngOnInit() {
        
    }

    selectImage(index: string | number) {
        this.selectedIndex = index;
        this.fragranceImage = this.images[index];
        this.fragranceArticleNo = this.articlenos[index];
        this.fragranceSpecification1 = this.specifications1[index];
        this.fragranceSpecification2 = this.specifications2[index];
        this.fragranceSpecification3 = this.specifications3[index];
        this.fragranceSpecification4 = this.specifications4[index];
        this.fragranceSpecification5 = this.specifications5[index];
        this.fragranceDescription = this.description[index];
    }

    onNoClick(): void {
        this.dialogbox.close();
    }
}